import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    library: [],
    localAnnexList: [],
    file_path: '',
    file_path_url: '',
    teacherRemarkObj:{},
    douyin_id: '',
    task_form:{},
    studentContent:'',
    studentInfo:{},
    accountInfo:{},
    worksInfo:{},
    studentLiveData:{},
    accountID: 0,
  },
  getters: {
    accountValue: (state) => {
        return state.accountID
    },
    libraries: (state) => {
      return state.library;
    },
    localAnnexLists: (state) => {
        return state.localAnnexList;
    },
    filePath: (state) => {
        return state.file_path;
    },
    filePathUrl: (state) => {
        return state.file_path_url;
    },
    teacherRemarkObj: (state) => {
        return state.teacherRemarkObj
    },
    getDouyinId: (state) => {
        return state.douyin_id
    },
    getTaskForm: (state) => {
        return state.task_form
    },
    getStudentContent: (state) => {
      return state.studentContent
    },
    getStudentInfo: (state) => {
      return state.studentInfo
    },
    getAccountInfo: (state) => {
      return state.accountInfo
    },
    getWorksInfo: (state) => {
      return state.worksInfo
    },
    getStudentLiveData: (state) => {
    return state.studentLiveData
    }
  },
  mutations: {
    setAccountId: (state, payload) => {
        state.accountID = payload
    },
    setLibrary: (state,playload) => {
      state.library = playload;
    },
    setLocalAnnexList: (state,playload) => {
      state.localAnnexList = playload;
    },
    setFilePath: (state,playload) => {
      state.file_path = playload;
    },
    setFilePathUrl: (state,playload) => {
      state.file_path_url = playload;
    },
    setteacherRemarkObj: (state,playload) => {
      state.teacherRemarkObj = playload
    },
    setDouyinId: (state,playload) => {
      state.douyin_id = playload
    },
    setTaskForm: (state,playload) => {
      state.task_form = playload
    },
    setStudentContent: (state,playload) => {
      state.studentContent = playload
    },
    setStudentInfo: (state,playload) => {
        state.studentInfo = playload
    },
    setAccountInfo: (state,playload) => {
      state.accountInfo = playload
    },
    setWorksInfo: (state,playload) => {
      state.worksInfo = playload
    },
    setStudentLiveData: (state, playload) => {
      state.studentLiveData = playload
    }
  },
  actions: {
    setLibraries: (context, args) => {
      context.commit("setLibrary", args)
    },
    setTaskFormC: (context, args) => {
      context.commit("setTaskForm", args)
    },
    setAccountValue: (context, args) => {
        context.commit("setAccountId", args)
    }
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
