<template>
    <div class="student-header">
        <div class="student-avatar-logo">
<!--                <span class="student-menu">-->
<!--                    <img src="../../assets/img/image/logo-m.png">-->
<!--                </span>-->
            <span class="student-avatar-title">{{ appName }}</span>
        </div>
        <div class="student-left">
            <div class="student-avatar-user">
                <span class="student-avatar">
                    <img :src="userIcon" alt="">
                     <span class="user-name text-overflow">{{name}}</span>
                 </span>
                <span class="student-icon" @click="viewHelp()">
                    <i class="iconfont">&#xe64e;</i>
                    <span>帮助</span>
                </span>
                <span class="student-icon" @click="getSetting()">
                    <i class="iconfont">&#xe629;</i>
                    <span>设置</span>
                </span>
                <span class="student-icon" @click="logout">
                    <i class="iconfont">&#xe88a;</i>
                    <span>退出</span>
                </span>
            </div>
        </div>
        <el-dialog
  title="修改密码"
  :visible.sync="editDialogVisible"
  width="600px" class="PublishVideo-dialog VideoDetail-dialog" 
  :before-close="handleCloseEdit">

 
  <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
    <el-form-item label="原密码" prop="oldPass">
    <el-input type="password" v-model="ruleForm.oldPass" show-password ></el-input>
  </el-form-item>
    <el-form-item label="新密码" prop="pass">
    <el-input type="password" v-model="ruleForm.pass" autocomplete="off" show-password></el-input>
  </el-form-item>
  <el-form-item label="确认密码" prop="checkPass">
    <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" show-password></el-input>
  </el-form-item>
</el-form>
  <span slot="footer" class="dialog-footer">
    <span slot="footer" class="dialog-footer">
                    <el-button type="primary" class="determine-btn" @click="subimitEditPwd('ruleForm')">确 定</el-button>
                    <el-button @click="handleCloseEdit">取 消</el-button>
                </span>
  </span>
</el-dialog>
    </div>
</template>

<script>
import {config} from "@/utils/config";
import {userModifyPassword} from "@/utils/apis";
    export default {
        name: "StudentHeaderModule",
        data() {
            var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
            if(value.length < 6 || value.length >40){
                callback(new Error('请输入6-40位密码！'));
            }
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
            return {
                appName: config.app_name,
                name: '',
                userIcon: require('../../assets/img/image/user_img.png'),
                editDialogVisible:false,
                ruleForm: {
          pass: '',
          checkPass: '',
          oldPass: '',
        },
        rules: {
          pass: [
            { validator: validatePass, trigger: 'blur', required: true }
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur', required: true }
          ],
          oldPass:[
            { validator: validatePass, trigger: 'blur', required: true }
          ],
        }
            }
        },
        mounted() {
            // this.username = localStorage.getItem('username');
            this.name = localStorage.getItem('name');
            if (!this.name) {
                this.$router.push('/');
            }
            ;
            let userIcon = localStorage.getItem('adminIcon');
            if (userIcon != '' && userIcon != undefined) {
                this.userIcon = userIcon;
            }
            ;
        },
        methods: {
            viewHelp(item) {
                this.$router.push({
                    path: '/student/works/helpcenter'
                });
            },
            getSetting(){
                this.editDialogVisible=true
            },
            handleCloseEdit(){
                this.editDialogVisible=false
                this.$refs.ruleForm.resetFields();
            },
            resetForm(formName) {
        this.$refs[formName].resetFields();
      },
            subimitEditPwd(formName){
                this.$refs[formName].validate((valid) => {
          if (valid) {
            let body={
                old_password:this.ruleForm.oldPass,
                password: this.ruleForm.pass 
            }
            if(this.ruleForm.oldPass===this.ruleForm.pass){
                this.$message.warning("原始密码与修改密码一致！")
            }else{
                userModifyPassword(body).then(res=>{
                // console.log("res",res);
                if(res.code==200){
                    this.$message.success("修改成功,请重新登录！")
                    this.handleCloseEdit() 
                    this.logout()    
                }else{
                    this.$message.error(res.message)
                }
               
            })
            }
           
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
            },
            logout() {
                this.$httpStudent.axiosGet(this.$api.loginout, (res) => {
                    if (res.code === 200) {
                        localStorage.clear();
                        this.$router.push('/');
                    } else if (res.code === 204) {
                        localStorage.clear();
                        this.$router.push('/');
                    }
                }, (err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .student-header {
        width: 100%;
        height: 60px;
        /*margin: 0 auto;*/
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: start;

        .student-avatar-logo {
            width: 221px;
            height: 60px;
            background: #685EF5;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                color: #333;
                font-size: 14px;
                height: auto;
                display: flex;
                align-items: center;
                margin-right: 4px;
            }

            .student-menu {
                img {
                    width: 40px;
                    height: 30px;
                    /*border-radius: 50%;*/
                    cursor: pointer;
                }
            }

            .student-avatar-title {
                font-size: 18px;
                font-weight: 300;
                display: inline-block;
                color: #fff;
            }
        }

        .student-left {
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
            height: 60px;
            box-shadow: 0px 0px 2px 0px rgba(51, 51, 51, 0.12);

            .student-avatar-user {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 20px;

                .student-avatar {
                    display: flex;
                    height: 32px;
                    line-height: 32px;
                    margin-right: 10px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 50%;
                        margin-right: 8px
                    }

                    .student-name {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                .student-icon {
                    display: flex;
                    line-height: 24px;
                    margin-right: 10px;

                    &:nth-of-type(2) {
                        i {
                            font-size: 16px;
                            margin-right: 6px;
                        }
                    }

                    & i {
                        color: #9d9ea7;
                        font-size: 24px;
                        margin-right: 4px;
                    }

                    &:hover.student-icon {
                        color: #409eff;
                    }

                    &:hover.student-icon i {
                        color: #409eff;
                    }
                }
            }
        }
    }
    .VideoDetail-dialog {
            .video-detail {
                /*height: 700px;*/

            }
            ::v-deep .el-dialog {
                border-radius: 10px;
                .el-dialog__header {
                    background-color: #F1F0FE;
                    border-radius: 10px 10px 0 0;
                }
                .el-dialog__body {
                    padding: 20px;
                }
                .el-dialog__footer {
                    text-align: center;
                    .dialog-footer {
                        .el-button {
                            padding: 7px 28px;
                            border-radius: 15px;
                        }
                        .determine-btn {
                            background: #594FEE;
                            border-color: #594FEE;
                            &:hover {
                                background: #2338E6;
                                border-color: #2338E6;
                            }
                        }
                    }
                }
                .header {
                    display: flex;
                    .video-cover {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 300px;
                        height: 200px;
                        margin-right: 20px;
                        cursor: pointer;
                        img.cover {
                           max-width: 100%;
                            max-height: 100%;
                        }
                        img.play-btn {
                            position: absolute;
                            width: 40px;
                            height: 40px;
                        }
                    }
                   
                }
                .VideoDetail-btn {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 20px 0;
                    .play-comment-like {
                        color: #594FEE;
                        .item {
                            margin-right: 20px;
                        }
                    }
                    .btn-del-video {
                        color: #FB0360;
                        i {
                            margin-right: 10px;
                            font-size: 14px;
                        }
                    }
                }
                .operating-video {
                    color: #5F5C74;
                }
                .comment-content {
                    margin: 20px 0;
                    border-top: 1px solid #EBEBF9;
                    .comment-header {
                        color: #5F5C74;
                        font-size: 16px;
                        margin: 20px 0;
                        display: block;
                    }
                    .comment {
                        height: 182px;
                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }
                        span.no-data {
                            display: block;
                            text-align: center;
                        }
                        
                      
                    }
                }
                .did-not-pass {
                    color: #FB0338;
                    .time {
                        margin-bottom: 10px;
                        span:nth-child(1) {
                            margin-right: 20px;
                        }
                    }
                    .detail {
                        span:nth-child(1) {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
        .PublishVideo-dialog {
            ::v-deep .el-dialog__body {
                // height: 126px;
                .type {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .el-radio__input.is-checked .el-radio__inner {
                         border-color: #DCDFE6;
                        background: transparent;
                    }
                    .el-radio__input.is-checked .el-radio__inner::after {
                        width: 6px;
                        height: 6px;
                        background-color: #9ACC5B;
                    }
                    .el-radio__input.is-checked+.el-radio__label {
                        color: #606266;
                    }
                }
            }
        }

::v-deep .el-form-item--feedback .el-input__validateIcon{
    display: none;
}
</style>
