<template>
    <div class="admin-header">
        <div class="header">
            <div class="header-logo">
                <img src="../../assets/img/image/logo-blue.png" alt="" style="width: 140px">
            </div>
            <div class="nav-wrapper">
                <el-menu :default-active="adminTab" class="nav" mode="horizontal">
                    <el-menu-item index="adminEducational">
                        <router-link class="nav-item" to="/admin/education/index">教务管理</router-link>
                    </el-menu-item>
                    <el-menu-item index="adminTrain">
                        <router-link class="nav-item" to="/admin/train/classification">实训管理</router-link>
                    </el-menu-item>
                    <el-menu-item index="adminScript">
                        <router-link class="nav-item" to="/admin/script/classification">脚本管理</router-link>
                    </el-menu-item>
                    <el-menu-item index="adminTask">
                        <router-link class="nav-item" to="/admin/task/classifies">任务管理</router-link>
                    </el-menu-item>
                    <el-menu-item index="adminGoodsManage">
<!--                        /admin/goodsManage/promote-->
                        <router-link class="nav-item" to="/admin/goodsManage/list">商品管理</router-link>
                    </el-menu-item>
                    <!--<el-menu-item index="adminFinance">-->
                        <!--<router-link class="nav-item" to="/admin/education/index">财务管理</router-link>-->
                    <!--</el-menu-item>-->
                    <el-menu-item index="adminHelp">
                        <router-link class="nav-item" to="/admin/help/classifies">帮助中心</router-link>
                    </el-menu-item>
                    <el-menu-item index="adminAnnouncement">
                        <router-link class="nav-item" to="/admin/announcement">公告管理</router-link>
                    </el-menu-item>
                    <el-menu-item>
                        <router-link target="_blank" class="nav-item" to="/data/spectaculars">数据看板</router-link>
                    </el-menu-item>
                </el-menu>
                <div class="user-info">
                    <div class="user-avatar">
                        <!--<img src="../../assets/img/ceshi/acfun.png" alt="">-->
                        <img :src="userIcon" alt="">
                    </div>
                    <span class="user-name text-overflow">{{username}}</span>
                    <span class="top-sign-out" @click="logout">退出</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeaderModule",
        data() {
            return {
                adminTab: '',
                username: '',
                userIcon:require('../../assets/img/image/user_img.png'),
            }
        },
        // watch: {
        //     $route(val) {
        //         // console.log('123',val)
        //         this.adminTab = val.matched[1].name;
        //     }
        // },
        mounted(){
            this.username = localStorage.getItem('username');
            if (!this.username) {
                this.$router.push('/admin/login');
            };
            let userIcon = localStorage.getItem('adminIcon');
            if (userIcon != '' && userIcon != undefined) {
                this.userIcon = userIcon;
            };
            this.adminTab = this.$route.matched[1].name;
        },
        methods: {
            logout() {
                this.$http.axiosGet(this.$api.loginout, (res) => {
                    localStorage.clear();
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            duration: 500,
                            message: '退出成功！',
                        });
                        this.$router.push('/admin/login');
                    }
                },(err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .nav {
        flex: 1;
        width: 1%;
        border-bottom: none;
        .el-menu-item {
            padding: 0;
            .nav-item {
                display: block;
                padding: 0 20px;
            }
        }
    }
    .top-sign-out{
        cursor: pointer;
    }
</style>